import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Button, TextField, MenuItem, CircularProgress } from '@mui/material';

import Select from 'react-select';

import AdditionalQuestionContext from '../Store/AdditionalQuestionContext';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import '../App.scss';

const jobTitleValues = [
  {value: "Other", label: "Other"},
  {value: "Administrative Pastor", label: "Administrative Pastor"},
  {value: "Lead Pastor", label: "Lead Pastor"},
  {value: "Executive Director", label: "Executive Director"},
  {value: "Campus Pastor", label: "Campus Pastor"},
  {value: "Children's Pastor", label: "Children's Pastor"},
  {value: "College Pastor", label: "College Pastor"},
  {value: "Community Pastor", label: "Community Pastor"},
  {value: "Connections Pastor", label: "Connections Pastor"},
  {value: "Creative Arts Pastor", label: "Creative Arts Pastor"},
  {value: "Discipleship Pastor", label: "Discipleship Pastor"},
  {value: "Executive Pastor", label: "Executive Pastor"},
  {value: "Family Pastor", label: "Family Pastor"},
  {value: "High School Pastor", label: "High School Pastor"},
  {value: "Leadership Development Pastor", label: "Leadership Development Pastor"},
  {value: "Middle School Pastor", label: "Middle School Pastor"},
  {value: "Missions Pastor", label: "Missions Pastor"},
  {value: "Outreach Pastor", label: "Outreach Pastor"},
  {value: "Personal Assistant to Lead Pastor", label: "Personal Assistant to Lead Pastor"},
  {value: "Senior Pastor", label: "Senior Pastor"},
  {value: "Small Group Pastor", label: "Small Group Pastor"},
  {value: "Stewardship Pastor", label: "Stewardship Pastor"},
  {value: "Student Pastor", label: "Student Pastor"},
  {value: "Teacher Pastor", label: "Teacher Pastor"},
  {value: "Worship Pastor", label: "Worship Pastor"},
  {value: "Administrative Assistant", label: "Administrative Assistant"},  
  {value: "AV Director", label: "AV Director"},
  {value: "Bookkeeper", label: "Bookkeeper"},
  {value: "Chief Financial Officer", label: "Chief Financial Officer"},
  {value: "Children's Ministry Coordinator", label: "Children's Ministry Coordinator"},
  {value: "Communications Coordinator", label: "Communications Coordinator"},
  {value: "Communications Director", label: "Communications Director"},
  {value: "Communications Project Manager", label: "Communications Project Manager"},
  {value: "Content Writer", label: "Content Writer"},
  {value: "Digital Media Analyst", label: "Digital Media Analyst"},
  {value: "Events Coordinator", label: "Events Coordinator"},
  {value: "Facility Manager", label: "Facility Manager"},
  {value: "Graphic Designer", label: "Graphic Designer"},
  {value: "Groups Director", label: "Groups Director"},
  {value: "Guest Services Director", label: "Guest Services Director"},
  {value: "Human Resources Coordinator", label: "Human Resources Coordinator"},
  {value: "IT Director", label: "IT Director"},
  {value: "Mission and Outreach Coordinator", label: "Mission and Outreach Coordinator"},
  {value: "Office Manager", label: "Office Manager"},
  {value: "Online Community Manager", label: "Online Community Manager"},
  {value: "Pastoral Care Minister", label: "Pastoral Care Minister"},
  {value: "Preschool Director", label: "Preschool Director"},
  {value: "Production Coordinator", label: "Production Coordinator"},
  {value: "Social Media Manager", label: "Social Media Manager"},
  {value: "Video Producer", label: "Video Producer"},
  {value: "Volunteer Coordinator", label: "Volunteer Coordinator"},
  {value: "Web Developer", label: "Web Developer"},
  {value: "Worship Leader", label: "Worship Leader"},
];

const Details = (props) => {

  const addCtx = useContext(AdditionalQuestionContext);

  let navigate                          = useNavigate();
  const { id }                          = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving]         = useState(false);
  const [isLoading, setIsLoading]       = useState(false);
  const [isFormValid, setIsFormValid]   = useState(false);
  const [isMember, setIsMember]         = useState(true);

  const [firstName, setFirstName]       = useState("");
  const [lastName, setLastName]         = useState("");
  const [fullName, setFullName]         = useState("");
  const [email, setEmail]               = useState("");
  const [jobTitle, setJobTitle]         = useState("");
  const [jobTitleMember, setJobTitleMember]   = useState("");
  const [churchName, setChurchName]     = useState("");
  const [zipCode, setZipCode]           = useState("");
  const [avgAttendance, setAvgAttendance] = useState("");
  const [selectedValue, setSelectedValue]     = useState();

  const [firstNameError, setFirstNameError]   = useState(false);
  const [lastNameError, setLastNameError]     = useState(false);
  const [fullNameError, setFullNameError]     = useState(false);
  const [emailError, setEmailError]           = useState(false);
  const [jobTitleError, setJobTitleError]     = useState(false);
  const [churchNameError, setChurchNameError] = useState(false);
  const [zipCodeError, setZipCodeError]       = useState(false);
  const [avgAttendanceError, setAvgAttendanceError] = useState(false);

  useEffect(() => {
    // document.body.style.backgroundColor = "#152036";
    console.log("Do we run again");
    if(searchParams.get("member") != null) {
      getUserDetails();
    } else {
      setIsMember(false);
    }
    // go to last additional question when you click back on Details page 
    localStorage.setItem("backFromDetailsPage", true);
  }, []);

  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  useEffect(() => {
    const updateFormCtxDetails = (fieldName, value) => {
      if (value !== addCtx.formDetails?.[fieldName]) {
        addCtx.addFormDetails(prev => ({ ...prev, [fieldName]: value }));
      }
    };
    // updateFormCtxDetails("firstName", firstName);
    // updateFormCtxDetails("lastName", lastName);
    updateFormCtxDetails("fullName", fullName);
    updateFormCtxDetails("email", email);
    updateFormCtxDetails("jobTitle", jobTitle);
    updateFormCtxDetails("churchName", churchName);
    updateFormCtxDetails("zipCode", zipCode);
    updateFormCtxDetails("avgAttendance", avgAttendance);

  // }, [firstName, lastName, email, jobTitle, churchName, zipCode]);
  }, [fullName, email, jobTitle, churchName, zipCode, avgAttendance]);

  useEffect(() => {
    let formDetails = addCtx.formDetails;

    // if (formDetails?.firstName?.length)  setFirstName(formDetails.firstName);
    // if (formDetails?.lastName?.length)   setLastName(formDetails.lastName);
    if (formDetails?.fullName?.length)   setFullName(formDetails.fullName);
    if (formDetails?.email?.length)      setEmail(formDetails.email);
    if (formDetails?.churchName?.length) setChurchName(formDetails.churchName);
    if (formDetails?.zipCode?.length)    setZipCode(formDetails.zipCode);
    if (formDetails?.avgAttendance?.length) setAvgAttendance(formDetails.avgAttendance);

    if (formDetails?.jobTitle?.length) {
      let selected = jobTitleValues?.find(job => job.value === formDetails?.jobTitle);
      // console.log("typeof selected", typeof selected, selected);
      setSelectedValue(selected);
      selected?.value && setJobTitle(selected?.value);
    }
  }, [addCtx.formDetails])

  console.log("addCtx.formDetails", addCtx.formDetails);
  // console.log("typeof jobTitle", typeof jobTitle, jobTitle);

  const getUserDetails = () => {
    setIsLoading(true);
  
    fetch(process.env.REACT_APP_API_URI + '/v1/user/' + searchParams.get("member"), {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        setIsMember(true);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setFullName(data.fullName);
        setEmail(data.email);
        // setJobTitle("none");
        setJobTitle("");
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  const updateFirstName = (event) => {
    setFirstName(event.target.value);
    checkFormValid();
  }

  const updateLastName = (event) => {
    setLastName(event.target.value);
    checkFormValid();
  }
  
  const updateFullName = (event) => {
    setFullName(event.target.value);
    checkFormValid();
  }

  const updateEmail = (event) => {
    setEmail(event.target.value);
    checkFormValid();
  }

  // const updateJobTitle = (event) => {
  const updateJobTitle = (selectedOption) => {
    // console.log(event.target.value);
    console.log(selectedOption.value);
    setSelectedValue(selectedOption);
    setJobTitle(selectedOption.value);
    checkFormValid();
  }
  
  const updateChurchName = (event) => {
    setChurchName(event.target.value);
    checkFormValid();
  }

  const updateZipCode = (event) => {
    setZipCode(event.target.value);
    checkFormValid();
  }
  
  const updateAvgAttendance = (event) => {
    setAvgAttendance(event.target.value);
    checkFormValid();
  }
  
  const checkFormValid = () => {
    if(isMember) {
      // if(firstName && lastName) {
      if(fullName && avgAttendance) {
        console.log("We get here");
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      // if(firstName && lastName && email && emailRegex.test(email) && jobTitle && churchName && zipCode.length > 3 && zipCode.length < 13) {
      if(fullName && email && emailRegex.test(email) && jobTitle && churchName && avgAttendance && zipCode.length > 3 && zipCode.length < 13) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  }

  const showErrors = () => {
    if(!firstName) {
      setFirstNameError(true);
      // setTimeout(() => { console.log(firstNameError); }, 2000);
    }
    if(!lastName) {
      setLastNameError(true);
    }
    if(!fullName) {
      setFullNameError(true);
    }
    if(!emailRegex.test(email)) {
      setEmailError(true);
    }
    if(!jobTitle) {
      setJobTitleError(true);
    }
    if(!churchName) {
      setChurchNameError(true);
    }
    if(zipCode.length < 4) {
      setZipCodeError(true);
    }
    if(!avgAttendance) {
      setAvgAttendanceError(true);
    }
  }

  const submitToApi = () => {
    
    let apiCall         = '/v1/assessment/' + id + '/leader';
    if(isMember) {
      apiCall           = '/v1/assessment/' + id + '/member/' + searchParams.get("member");
    }

    let jsonBody        = JSON.stringify({
      details: {
        // "firstName": firstName,
        "fullName": fullName,
        "lastName": lastName,
        "email": email,
        "jobTitle": jobTitle,
        "churchName": churchName,
        "zipCode": zipCode,
        "avgWeeklyAttendance": avgAttendance,
      },
      formVersion: 2
    });

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody,
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        localStorage.removeItem("assessmentAnswers");
        localStorage.removeItem("additionalAnswers");
        localStorage.removeItem("backFromDetailsPage");
        navigate("/results/" + id + "?" + searchParams, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });

  }

  const handleSubmit = () => {

    setIsSaving(true);
    console.log("Runnign");


    // if(isMember && firstName && lastName && jobTitle) {
    if(isMember && fullName && jobTitle && avgAttendance) {
      submitToApi();
    // } else if(!isMember && firstName && lastName && emailRegex.test(email) && jobTitle && churchName && zipCode.length > 3 && zipCode.length < 13) {
    } else if(!isMember && fullName && emailRegex.test(email) && jobTitle && churchName && avgAttendance && zipCode.length > 3 && zipCode.length < 13) {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }

  }

  const showButton = () => {
    if(isSaving) {
      return <div className="button-progress"><CircularProgress style={{ color: '#FFFFFF' }} size={23} /></div>
    }
    return "SHOW MY RESULTS";
  }

  const showHintText = (field) => {
    if(field == "firstName") {
      if(firstNameError) {
        return <div className="error-text">Please confirm your first name.</div>
      }
    }
    if(field == "lastName") {
      if(lastNameError) {
        return <div className="error-text">Please confirm your last name.</div>
      }
    }
    if(field == "fullName") {
      if(fullNameError) {
        return <div className="error-text">Please confirm your full name.</div>
      }
    }
    if(field == "email") {
      if(emailError) {
        return <div className="error-text">Please enter a valid email address.</div>
      }
    }
    if(field == "jobTitle") {
      if(jobTitleError) {
        return <div className="error-text">Please select an option.</div>
      }
    }
    if(field == "churchName") {
      if(churchNameError) {
        return <div className="error-text">Please confirm your church name.</div>
      }
    }
    if(field == "zipCode") {
      if(zipCodeError) {
        return <div className="error-text">Please confirm your ZIP code.</div>
      }
    }
    if(field == "avgAttendance") {
      if(avgAttendanceError) {
        return <div className="error-text">Please confirm your avg. weekly attendance.</div>
      }
    }
  }

  const removeHintText = (field, e) => {

    // change active input field title color 
    if(e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#414042");
      e.target.closest(".form-detail").querySelector(".input-title").style.color = "#678F9D";
    }

    if(field == "firstName") {
      setFirstNameError(false);
    }
    if(field == "lastName") {
      setLastNameError(false);
    }
    if(field == "fullName") {
      setFullNameError(false);
    }
    if(field == "email") {
      setEmailError(false);
    }
    if(field == "jobTitle") {
      setJobTitleError(false);
    }
    if(field == "churchName") {
      setChurchNameError(false);
    }
    if(field == "zipCode") {
      setZipCodeError(false);
    }
    if(field == "avgAttendance") {
      setAvgAttendanceError(false);
    }
  }

  const prevQuestion = () => {
    // if (hasAdditional) {
    //   navigate("/assessment-additional/" + id, { replace: true });
    // } else {
    //   navigate("/assessment/" + id, { replace: true });
    // }
    
    if(isMember) {
      navigate('/assessment-additional/' + id + '?member=' + searchParams.get("member") + "&" + searchParams, { replace: true });
    } else {
      navigate('/assessment-additional/' + id + "?" + searchParams, { replace: true });
    }
  }

  if (isLoading) {
		return <div className="container details loading"><CircularProgress style={{ color: '#5DB256' }} size={60} /></div>
	}
  
  return (
    <div className="container details">
      
      <div className="details-container">
        
        <h1>Thank you, now let's get to your results!</h1>
        <div style={{ height: isMember ? '45px' : '10px' }}></div>
        <p>As we tabulate your report, please confirm your details so that we can email you a copy.</p>

        <div className="form">

          {/* <div className="form-detail">
            <p className="input-title">First name<span className="mark-required">*</span></p>
            <TextField required hiddenLabel placeholder="Simon" value={firstName} onFocus={(e) => removeHintText("firstName", e)} onChange={(e) => {updateFirstName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("firstName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Last name<span className="mark-required">*</span></p>
            <TextField required hiddenLabel placeholder="Peter" value={lastName} onFocus={(e) => removeHintText("lastName", e)}  onChange={(e) => {updateLastName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("lastName")}
          </div> */}
          
          <div className="form-detail">
            <p className="input-title">Full name<span className="mark-required">*</span></p>
            <TextField required hiddenLabel placeholder="Simon Peter" value={fullName} onFocus={(e) => removeHintText("fullName", e)}  onChange={(e) => {updateFullName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("fullName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Email<span className="mark-required">*</span></p>
            <TextField type="email" required hiddenLabel placeholder="speter@petra.church" disabled={isMember} value={email} onFocus={(e) => removeHintText("email", e)} onChange={(e) => {updateEmail(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("email")}
          </div>

          <div className="form-detail">
            <p className="input-title">Job title<span className="mark-required">*</span></p>
            {/* <TextField required select hiddenLabel value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
              <MenuItem key={0} value="none" disabled>Start typing</MenuItem>
              {jobTitleValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField> */}
            
            {/* Added react-select instead of MUI TextField */}
            <Select classNamePrefix="react-select" className="form-details-text react-select" 
              options={jobTitleValues} 
              onFocus={(e) => removeHintText("jobTitle", e)} 
              onChange={updateJobTitle} 
              value={selectedValue}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#678F9D44',
                  primary: '#678F9D',
                  neutral0: '#F4F4F4',
                  neutral50: '#AAAAAA',
                },
              })}
              placeholder={"Start typing or select"} />
            {showHintText("jobTitle")}
          </div>

          {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">ZIP code<span className="mark-required">*</span></p>
                <TextField required type="text" hiddenLabel placeholder="11618" value={zipCode} 
                  onFocus={(e) => removeHintText("zipCode", e)} 
                  onChange={(e) => {updateZipCode(e)}} 
                  onInput = {(e) => {
                    return e.target.value = e.target.value?.toString().slice(0,12)
                  }}
                  fullWidth variant="standard" size="normal" 
                  InputProps={{ disableUnderline: true }} className="form-details-text" 
                />
                {showHintText("zipCode")}
              </div>
            ) : null
          }
          {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">Church name<span className="mark-required">*</span></p>
                <TextField required hiddenLabel placeholder="Petra Church" value={churchName} onFocus={(e) => removeHintText("churchName", e)} onChange={(e) => {updateChurchName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("churchName")}
              </div>
            ) : null
          }

          <div className="form-detail">
            <p className="input-title">Average weekly attendance<span className="mark-required">*</span></p>
            <TextField required hiddenLabel placeholder="1200" value={avgAttendance} onFocus={(e) => removeHintText("avgAttendance", e)} onChange={(e) => {updateAvgAttendance(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("avgAttendance")}
          </div>
          
        </div>

        <div className="buttons" style={{ marginTop: isMember ? "45px" : "auto" }}>
          <div className="back-btn">
            <Button onClick={prevQuestion}>
              <img src={prevArrow} />&nbsp;&nbsp;PREV
            </Button>
          </div>
          
          <div className="show-results">
            <Button onClick={handleSubmit} disabled={isSaving}>
              {showButton()}
            </Button>
          </div>
        </div>

      </div>
    </div>
  );
  
}
export default Details;